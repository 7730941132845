<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">A must-have E-ticket package for sightseeing in Hiroshima. Select popular facilities and restaurants in Hiroshima area. You can choose 3  of your favorite facilities within a week. Present the QR code on the E-ticket to easily enter the venue.</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">How to use</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ● The same facility can only be used once, and the same facility can not be re-entered.<br>
          ● Ticket Validity Period: <span>90 Days</span> After Purchase.<br>
          EX: Tickets Purchased On <span>7/1</span> ⇒ <span>Ticket Validity Period:7/1~9/28.</span><br>
          ● Ticket Validity Period: After Scanning The Code At The First Facility, It Is Valid For <span>7 Days.</span><br>
          EX: If You Start To Use It On 7/1 ⇒ Validity Period:<span> 7/1~7/7.</span><br>
          ● Available Facilities: You Can Use <span>3</span>  Facilities During The Valid Period.<br>
          ● Please Note That When You Purchase More Than One Ticket In A Reservation, The Starting Date Will Apply To All Tickets. <br>
          (<span>The Validity Period Is 7 Days</span>).<br>
          ● When The Ticket Has Been Used, The <span>7 Day</span> Validity Period Will Not Exceed The <span>90 Day</span> Validity Period Of The Ticket Itself.<br>
          EX: Ticket Period: <span>7/1~9/28</span>, If The Ticket Starts On <span>9/27</span>, The Valid Time Is: <span>9/27~9/28</span>.<br>
          ● Facilities That Are Unused Cannot Be Refunded.<br>
          ● Please Check The Information About Each Facility, Business Hours, And Public Holidays In Advance At The Following Links.
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='Notice'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='Redemption Location' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'wordWrap': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='Official Website' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- notice特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfhiroshima/en/havefun_title_en.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hfhiroshima/en/ferry_EN.png'),
          title: ['Cruise ferry one-way ticket between Hiroshima Port and Kure Port'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '1. Hiroshima Port Ujina Passenger Terminal 1F counter','Address: 1-13-26 Ujina Kaigan, Minami-ku, Hiroshima-shi','2. Kure Port (Central Pier) Terminal 1F counter','Address: 4-44 Takaramachi, Kure-shi, Hiroshima'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://setonaikaikisen.co.jp/language/en/cruise/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Available Routes:','<br>・ Hiroshima Port → Kure Port<br>・Kure Port → Hiroshima Port','<br>※ Each route is valid for a one-way trip.'] },
                { cid: 2, text: ['Please be aware that if you do not authenticate the QR code at the counter, you will not be able to board the ferry directly.'] },
                { cid: 3, text: ['The high-speed ferry (Super Jet) between Hiroshima and Kure is not available.'] },
                { cid: 4, text: ['Please refer to the website for the schedule.'] },
                ]
            }
          ]
        },
        {
          id: 2,
          img: require('@/assets/images/hfhiroshima/en/ferry2_EN.jpg'),
          title: ['Hiroshima Port-Miyajima Port High-speed ship boarding ticket discount coupon 1000 yen worth'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '1. Hiroshima Port Ujina Passenger Terminal 1F, Window ① or ②',
                'Address:  1-13-26 Ujina Kaigan, Minami-ku, Hiroshima-shi',
                '2.Grand Prince Hotel Hiroshima 1F Travel Desk',
                'Address: 23-1 Moto-ujinacho, Minami-ku, Hiroshima City',
                '3.Miyajima Port 3rd Pier Ticket Sales Office',
                'Address: Near Miyajimacho Ebisumachi, Hatsukaichi-shi, Hiroshima'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://setonaikaikisen.co.jp/language/en/highspeedship/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Available Routes:','<br>・Hiroshima Port → Miyajima Port<br>・Prince Hotel Front → Miyajima Port<br>・Miyajima Port → Hiroshima Port<br>・Miyajima Port → Prince Hotel Front','<br>※You can enjoy a 1,000 yen coupon for any of these routes.'] },
                { cid: 2, text: ['Please note that if you board the ferry directly without authenticating the QR code at the counter, you will not be allowed to board.'] },
                { cid: 3, text: ['Please check the website for the schedule.'] },
                { cid: 4, text: ['If there is a fare difference, you will need to pay it at the venue.'] },
                { cid: 5, text: ['Please be aware that it may be fully booked and unavailable.'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hfhiroshima/en/Orizuru_EN.png'),
          title: ['Orizuru Tower admission ticket discount coupon 1,000 yen worth'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒730-0051 No. 2-1, Otemachi 1-chome, Naka-ku, Hiroshima City, Hiroshima Prefecture'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.orizurutower.jp/en/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1,000 yen coupon.'] },
                { cid: 2, text: ['It may be temporarily closed due to events, etc. Please check the official website for more details before visiting.'] },
                { cid: 3, text: ['【About re-entry】','<br>・On the designated day (only), multiple re-entries are allowed.<br>・If you want to re-enter the venue, please inform the staff at the gate and get a date stamp on your hand. Re-entry requires checks of your admission ticket and the stamp.'] },
                { cid: 4, text: [
                  '【Entering the observatory】',
                  '<br>・In order to prevent accidents in the venue, items deemed inappropriate by the staff, such as suitcases or large pieces of luggage, may not be allowed to be carried. There are coin lockers (next to the entrance on the 1st floor / in rental), so please use them due to associated regulations.',
                  '<br>・Please note that food and beverages purchased outside the venue are not allowed.',
                  '<br>・It is strictly forbidden to use a tripod to take pictures, so as not to cause troubles to other guests. '
                ] },
                { cid: 5, text: ['Please note that the view of the surroundings may not be available due to weather, or some facilities may be closed due to strong wind, etc.'] },
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfhiroshima/en/Peacecle_EN.png'),
          title: ['Hiroshima city share cycle “Peacecle” 1-day ticket [Hiroshima city]'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                'SS Building 1F (Peascul Management Office), Kyobashicho, Minami Ward, Hiroshima, 732-0828 '],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://docomo-cycle.jp/hiroshima/en/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['QR code authentication is not accepted at other 1-day pass sales counters.'] },
                { cid: 2, text: ['Valid until 23:59 on the same day.'] },
                { cid: 3, text: ['Bicycles are limited to individuals with a height of 145 cm or taller.'] },
                { cid: 4, text: ['Please return the bicycle to one of the 140 designated cycle ports within the city.'] },
                { cid: 5, text: ['There are time restrictions for the use/return of bicycles at some cycle ports. Please check the website for details.'] },
                { cid: 6, text: ['Helmet usage is mandatory when riding a bicycle. Helmets can be rented for a fee if needed.'] },
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfhiroshima/en/ekie_EN.png'),
          title: ['Shopping center "ekie" in front of JR Hiroshima Station Shopping Voucher - 1,500 yen'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '1-2, Matsubara-cho, Minami-ku, Hiroshima City 732-0822(2nd-floor information counter)'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.ekie.jp/lang/english.html'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please present the shopping voucher at the time of payment at each store. (QR code presentation is not accepted. Please be sure to exchange the coupon voucher and then use it at each store.)'] },
                { cid: 2, text: ['This voucher cannot be exchanged for cash, and no change will be given.'] },
                { cid: 3, text: ['There will be no refunds if you are unable to use it due to temporary closures, full occupancy, or any other circumstances.'] },
                { cid: 4, text: ["Stores not eligible for the voucher: Japan Travel, Seven-Eleven Heart Inn, Date Eye Clinic, Momiji Pharmacy, Hiroshima Station Building Kanohara Dental Clinic, Hiroshima Station Internal Medicine and Children's Clinic, Kawai Juku Manabis."] },
                { cid: 5, text: ["Vouchers (physical) are valid for 7 days."] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfhiroshima/en/Sozo_EN.png'),
          title: ['1,500 yen gift certificate that can be used at “Hiroshima Okonomi Monogatari Ekimae Square”'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '6F “Okonomiyaki Sozo”, Hiroshima Full Focus Building, 10-1 Matsubaracho, Minami-ku, Hiroshima City, Hiroshima Prefecture 732-0822'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://ekimae-hiroba.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please present the shopping voucher at each store during checkout. (QR code presentation is not accepted. Be sure to exchange the voucher for the designated product before using it at each store.)'] },
                { cid: 2, text: ['We do not provide cash in exchange for this voucher.'] },
                { cid: 3, text: ['We are not responsible for theft, loss, or any other incidents involving this voucher.'] },
                { cid: 4, text: ["There will be no refunds if you are unable to use the voucher due to temporary closures, full occupancy, or any other circumstances."] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hfhiroshima/en/Bic_Camera_EN.png'),
          title: ['1,000 yen worth of gift certificates for the "Bic Camera Hiroshima Ekimae Store" home appliance store in front of JR Hiroshima Station'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '1st floor general information counter, 5-1 Matsubara-cho, Minami-ku, Hiroshima-shi, Hiroshima 732-0822'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.biccamera.com/bc/c/super/okaimono/oshiharai/coupon/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please present the shopping voucher at each store during checkout. (QR code presentation is not accepted. Be sure to exchange the voucher for the designated product before using it at each store.)'] },
                { cid: 2, text: ['We do not provide cash in exchange for this voucher.'] },
                { cid: 3, text: ['We are not responsible for theft, loss, or any other incidents involving this voucher.'] },
                { cid: 4, text: ["This voucher cannot be exchanged for postal stamps, postcards, tobacco, or any other products specified by our company."] },
                { cid: 5, text: ["There will be no refunds if you are unable to use the voucher due to temporary closures, full occupancy, or any other circumstances."] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfhiroshima/en/LobbyLounge_EN.jpg'),
          title: [`Hotel Granvia Hiroshima Lobby's Café "Lobby Lounge" Cake Set Meal`],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒732-0822, Lobby Lounge (lobby mezzanine), 1-5 Matsubaracho, Minami Ward, Hiroshima City, Hiroshima'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.hgh.co.jp/english/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ["Include a variety of (dessert) chef's cakes and coffee or tea."] },
                { cid: 2, text: ['Please check the website in advance for operating hours.','<br>※The seats may not be available due to temporary closures or full occupancy.'] },
                { cid: 3, text: ['The pictures are for reference only.  '] },
                { cid: 4, text: ["Additional charges will be paid by a customer."] },
                { cid: 5, text: ["No takeout allowed."] },
                ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfhiroshima/en/dahe_EN.jpg'),
          title: [`Yamato Museum Admission Ticket + 1000 Japanese Yen Discount Coupon for the Museum Gift Shop`],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '5-20 Takara-machi,Kure City,Hiroshima 737-0029'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://yamato-museum.com/leaflet/guidance-leaflet-english/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ["Please pick up the 1,000 Japanese Yen discount coupon when entering the museum."] },
                { cid: 2, text: ["The 1,000 Japanese Yen coupon can be used at the museum's shop and no change will be given."] },
                { cid: 3, text: ['Please check the official website for operating hours in advance.'] },
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFHiroshima&l=en')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-hiroshima-1-week-free-pass/en', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>